/* ---------------- Nav Begin ---------------*/

.contNav
{
    position: sticky;
    top: 0;
    z-index: 1;
}
nav
{
    height: 100px;
    width: 100%;
    display: flex;
    margin: 0 auto;
    padding: 0 10px;
}
nav a {color: black;}

/* ---------> burger*/

.burger
{
    display:none;
}

/* ---------> avatar*/

    .contAvatar
    {
        height: 100%;
        padding: 2px 0;
        margin: 0;
    }
    .avatar
    {
        height: 100%;
        max-width: 100%;
    }
    

/* ---------> menu*/

    nav li{list-style: none;}
    .menu1
    {
        display: flex;
        justify-content: space-between;
        height: 100%;  
        flex-grow: 1;
    }
    .menu1>li
    {
        height: 100%;
        padding: 0 10px;
        flex-grow: 1;
        display: flex;
        justify-content:flex-start;
        align-items: center;
        position: relative;
        background: none;
        border: unset;
    }
    .navLinkCont{
        height: 100%;
        width: 100%;
    }
    .navLink
    {
        font-family: ralewayExtraBold;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-size: 14px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .menuButton{display: none;}
    .menu2{display: none;}
    .menu1>li:hover .menu2
    {
        position: absolute;
        top: 100px;
        left: 0px;
        padding: 10px;
        display: flex;
        flex-direction: column;
        background-color: rgb(55, 9, 180);
        justify-content:space-between;
    }
    .menu1>li:hover
    {
        background-color: rgb(55, 9, 180);
    }
    .menu1>li:hover .navLink
    {
        color: rgb(255, 247, 142);
    }
    .menu2>li
    {
        padding: 10px 0;
        /* border-top: 1px solid rgb(255, 247, 142); */
        border-bottom: 1px solid rgb(255, 247, 142);
    }
    .menu2>li>a
    {
        color: white;
    }
    .subText
    {
        font-family: raleway;
        text-transform:inherit;
    }
    .menu3
    {
        display: none;
    }
    .menu2>li:hover .menu3
    {
        display: block;
        width: 100px;
        background-color: lightgray;
        position: absolute;
        top: 0px;
        right: -100px;

    }
    .menu3>li
    {
        padding: 10px 15px;
        position: relative;
        border: 1px solid white;
    }

/* ---------> social*/

    .social
    {
        padding: 5px 10px;
        display: flex;
        align-items: center;
    }
    .social svg
    {
        display: block;
        height: 30px;
        margin: 0 5px;
        border-radius: 10px;
    }

@media (max-width: 900px)
{
    .burger
    {
        display: flex;
        height: 100%;
        grid-column: 1;
        grid-row: 1;

    }
    .burger>svg
    {
        align-self: center;
        /* margin: 0 auto; */
    }
    nav
    {
        background-color: white;
        width: 100%;
        height: auto;
        display: grid;
        grid-template-columns: auto auto auto;
        grid-template-rows: 100px auto;
        justify-content: space-between;
    }
    .menu1
    {
        grid-column: 1 / 4;
        grid-row: 2;
        display:none;
        width: 100%;
        flex-direction: column;
    }
    .menu1>li
    {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-left: 10px;
        border-bottom: 1px solid rgb(55, 9, 180);
    }
    .navLinkCont {
        width: auto;
        height: auto;
    }
    .navLink
    {
        /* width: auto;
        display:inline;
        text-align: left; */
        font-family: raleway;
        text-transform: unset;
        letter-spacing: 0px;
        width: auto;
        padding: 10px;
        justify-content: left;
        height: auto;
        flex-grow: 1;
    }
    /* .menu1>li>img{
        width: 20%
    }; */
    .menuButton
    {
        height: 40px;
        margin: 0;
        padding: 10px 10px;
        border-radius: 20px;
        display: flex;
    }
    .menu1>li:hover .menu2
    {
        position: static;
        display: none;
        background-color: grey;
        
    }
    .menu2
    {
        position: static;
        flex-wrap: wrap;
        width: 100%;
        flex-direction: column;
        background-color: rgb(55, 9, 180);;
        padding: 10px 20px;
    }

    .contAvatar
    {
        justify-self: center;
        max-width: 100%;
        grid-column: 2;
        grid-row: 1;
        display: flex;
    }
    .avatar
    {
        justify-content: center;
        margin: 0 auto;
    }

}
@media (max-width: 480px)
{
    .social
    {
        padding-right: 10px;
        margin: 0;
        flex-direction: column;
        justify-content: center;
        align-items:center;
    }
    .social svg
    {
        display: block;
        height: 25px;
        margin: 0;
    }
}
/* ---------------- Nav END ---------------*/
/* ------------- Aside BEGIN -----------------*/

aside
{
  background-color: rgb(247, 255, 131);
  padding: 20px;
  position: relative;
}
  .card
  {
    width: 250px;
    height: 100%;
    margin: 0 10px;
    border: 1px solid black;
    border-radius: 20px;
    padding: 15px;
    background-color: white;
  }
  .card>div
  {
    width: 100%;
    height: 40%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .card img
  {
    max-width: 100%;
    max-height:100%;
  }
  .card>h3
  {
    margin: 0;
    margin-bottom: 5px;
    text-align: center;
  }
  .card>p
  {
    margin: 0;
    font-size: 14px;
  }

/* -------------- SLIDER ------------- */

  .sliderContainer
  {
    background-color: rgb(247, 255, 131);
    display: block;
    margin: 0 auto;
    position: relative;
  }
    .sliderContainer
    {
      width: 100%;
      height: 400px;
      overflow: hidden;
      position: relative;
    }
    #sliderPages
    {
      height: 100%;
      width: auto;
      position: absolute;
      margin-left: 40px;
      display: flex;
      transition: left;
      transition-duration: 0.5s;
    }
/*     .sliderPage
    {
      float: left;
      height: 100%;
      padding: 10px 60px;
      align-items: center;
      justify-content:space-between;
      width: 25%;
      display: flex;
      font-size: 5em;
    } */
    .slideButtons
    {
      position: absolute;
      width: 100%;
      top: 200px;
      display:flex;
      justify-content: space-between;
      align-items: center;
      z-index: 1;
      left: 0;
      padding: 20px;
    }
    .sliderArrow
    {
        height: 40px;
        margin: 0;
        padding: 10px 10px;
        border: 1px solid black;
        border-radius: 20px;
        display: inline-block;
        background-color: white;
    }
    #toPrev{transform-origin: 10% 50% 0;}
    #toNext{transform-origin: 90% 50% 0;}
    .slideSelect
    {
      display: none;
      margin: 0 auto;
      text-align: center;
      padding: 10px 0;
    }
      .slideSelect>a
      {
        
        margin: 10px;
        display: inline-block;
        text-decoration: none;
        transform: scale(0.7);
      }


@media (max-width: 480px)
{
  #sliderPages
  {
    margin-left: 0;
  }
  .card
  {
    /* width: 150px; */
    height: 100%;
    margin: 0 10px;
    border: 1px solid black;
    border-radius: 20px;
    padding: 15px;
    background-color: white;
  }
  .card h3
  {
    font-size: 18px;
  }
  .slideButtons
    {
      position: relative;
      top:unset;
      /* bottom: 0; */
    }

}

/* ------------- Aside END -----------------*/
/* ---------------- Normalize ---------------*/

*
{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
a, a:visited
{
    text-decoration: none;
    /* color: initial; */
}

/* ---------------- Fonts load ---------------*/

@font-face {
    font-family: raleway;
    src: url(./assets/fonts/Raleway/static/Raleway-Regular.ttf);
}
@font-face {
    font-family: ralewayBold;
    src: url(./assets/fonts/Raleway/static/Raleway-Bold.ttf);
}
@font-face {
    font-family: ralewayExtraBold;
    src: url(./assets/fonts/Raleway/static/Raleway-ExtraBold.ttf);
}

/* ---------------- General layout ---------------*/

nav, header, main, aside, footer
{
    max-width: 1100px;
    margin: 0 auto;
}
header, main, aside, footer
{
    padding: 10px;
}

/* ---------- General white body background -----------*/

.mainBlank
{
    background-color: white;
    width: 100%;
    min-height: 100vh;
    max-height: 100%;
}

/* ---------- General black body background -----------*/

.mainBlack
{
    background-color: black;
    max-width: 100%;
    color: white;
}

/* ---------- General button layout -----------*/

button, .link
{
    font-family: ralewayBold;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
    background-color: rgb(53, 50, 255);
    color: white;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    justify-content: center;
    margin: 10px;
    text-align: center;
    /* border: 1px solid black;   */
}
button
{
    margin-right: 2px;
    margin-bottom: 2px;
    box-shadow: 2px 2px #727272;
}
button:hover, .link:hover
{
    background-color: rgb(22, 0, 148);
}
button:active
{
    margin-top: 2px;
    margin-left: 2px;
    margin-right: 0;
    margin-bottom: 0;
    background-color: rgb(245, 255, 108);
    box-shadow: none;
    outline: none;
}
button:enabled
{
    outline: none;
}
ol
{
    padding-left:15px;
}
li
{
    font-family: raleway;
}
ol li::before
{
    border: 1px solid black;
    background-color: aqua;
    color: crimson;
}
/* img
{
    width: 100%;
} */

/* ---------------- Body BEGIN ---------------*/

body {
    height: 100vh;
}

.App
{
    background-attachment: fixed;
    background-position-x: center;
    /* background-position-y: -400px; */
    /* background-size: 50%; */
    background-size: 2000px auto;
    width: 100%;
    height: 100vh;
    overflow-y: scroll;

}
h1
{
    font-family: ralewayBold;
    font-size: 30px;
    letter-spacing: 5px;
    color: black;
    text-transform: uppercase;
    /* background-color: rgb(0, 0, 0, 0.4); */
    border-radius: 10px;
    padding: 0 20px;
    margin: 10px 0;
}
h2
{
    margin: 20px 0;
    font-family: ralewayBold;
    text-transform: uppercase;
    letter-spacing: 2px;
    /* text-align: center; */
}
h3
{
    margin: 20px 0;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 200;
    font-style: italic;
    font-size: 24px;
    color: rgb(92, 92, 92);
    /* margin: 10px 0; */
}
h4
{
    margin: 15px 0;
    font-family: raleway;
    font-weight:900;
    text-transform: uppercase;
    /* letter-spacing: 2px; */
    /* text-align: center; */
}
p
{
    margin: 10px 0;
    font-family: raleway;
    color: black;
    font-size: 18px;
    letter-spacing: 1px;
}
.serviceList
{
    display: flex;
    flex-wrap: wrap;
}
.service
{
    margin: 10px;
    width: 300px;
    height: 500px;
    border-radius: 20px;
    overflow: hidden;
    /* flex-grow: 1; */
}
.service>*
{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 auto;
    text-align: center;
}
.service>h2
{
    background-color: rgb(255, 244, 94);
    height: 20%;
    padding: 20px;
    display: flex;
    align-items: center;

}
.service>ul
{
    background-color: rgb(233, 233, 233);
    padding: 10px;
    height: 80%;
}
.service>a
{
    background-color: rgb(53, 50, 255);
    padding: 20px;
    font-family: ralewayExtraBold;
    font-size: 20px;
    text-transform: uppercase;
    color: white;
}
.service li
{
    list-style: none;
    font-family: raleway;
    font-weight: 400;
    margin: 5px 0;
    width: 100%;
    text-align: center;
    font-size: 18px;
    /* letter-spacing: 1px; */
}
.webComparison
{
    display: grid;
    grid-template-columns: auto 25% 25% 25%;
    grid-template-rows: auto auto auto auto;
    grid-auto-flow: column;
}
.cell
{
    padding: 10px;
}
.barCont
{
    width: 100%;
    height: 20px;
    border: 1px solid black;
    padding: 2px;
    border-radius: 10px;
}
.barFill
{
    background-color: blue;
    height: 100%;
    border-radius: 10px;
}
.icon
{
    width: 70%;
    color:  #cac702f3;
}
.iconRound
{
    border-radius: 50%;
    background-color: rgb(89, 71, 252);
    display: flex;
    width: fit-content;
    padding: 15px;
}
.flex
{
    display: flex;
    gap: 10px;
    align-items: center;
}
.inline
{
    display: inline;
}
.proyTable
{
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    align-items: center;
}
.proyTable>div
{
    width: 100%;
    height: 100%;
    padding: 5px;
    display: grid;
    grid-template-columns: 100%;
    align-items: center;
}
.proyTable>.headers
{
    display: none;
}
.headers>p
{
    font-weight: 700;
    /* font-style: italic; */
}
.proyTable>.first
{
    display: flex;
}
.proyTable p
{
    margin: 0px;
}
.backY
{
    background-color: rgb(255, 255, 175);
}
.disp
{
    display: none;
}
.grid_21
{
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 5px;
}
.gallery
{
    position: absolute; 
    top: 10px;
    left: 10px;
    width: calc(100% - 40px);
    height: calc(100vh - 20px);
    background-color: gold;
    z-index: 1;
}
@media (max-width: 900px)
{
    .serviceList
    {
        flex-wrap: wrap;
    }
    .service
    {
        width: 100%;
    }
    .proyTable>.headers
    {
        display: grid;
    }
    .proyTable
    {
        grid-template-rows: repeat(25, auto);
        grid-template-columns: 40% 60%;
        grid-auto-flow: column;
    }
    .proyTable>div
    {
        padding: 5px;
    }
    .first
    {
        background-color: rgb(255, 255, 175)
    }
    .disp
    {
        display: initial;
    }
    .webComparison
    {
        grid-template-rows: repeat(12, auto);
        grid-template-columns: auto;
    }
}
/* ---------------- Body END ---------------*/

/* -------------- SLIDER ------------- */


  .popupSlider
  {
    background-color: rgb(247, 255, 131);
    display: none;
    margin: 0 auto;
    position: absolute;
    left: 10px;
    top: 10px;
    width: calc(100vw - 40px);
    height: calc(100vh - 20px);
    overflow: hidden;
    z-index: 2;
  }

    #popupSliderPages
    {
      height: 100%;
      width: auto;
      position: absolute;
      margin-left: 0px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      transition: left;
      transition-duration: 0.5s;
    }
    .sliderPage
    {
      width: calc(100vw - 40px);
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .sliderPage>img
    {
      max-width: 100%;
      max-height: 100%;
    }
/*     .sliderPage
    {
      float: left;
      height: 100%;
      padding: 10px 60px;
      align-items: center;
      justify-content:space-between;
      width: 25%;
      display: flex;
      font-size: 5em;
    } */
    .slideButtons
    {
      position: absolute;
      width: 100%;
      top: 200px;
      display:flex;
      justify-content: space-between;
      align-items: center;
      z-index: 1;
      left: 0;
      padding: 20px;
    }
    .sliderArrow
    {
        height: 40px;
        margin: 0;
        padding: 10px 10px;
        border: 1px solid black;
        border-radius: 20px;
        display: inline-block;
        background-color: white;
    }
    .sliderClose
    {
        position: absolute;
        top: 10px;
        right: 10px;
        height: 40px;
        width: 40px;
        margin: 0;
        padding: 10px 10px;
        border: 1px solid black;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;        
        background-color: white;
    }
    #toPrev{transform-origin: 10% 50% 0;}
    #toNext{transform-origin: 90% 50% 0;}
    .slideSelect
    {
      display: none;
      margin: 0 auto;
      text-align: center;
      padding: 10px 0;
    }
      .slideSelect>a
      {
        
        margin: 10px;
        display: inline-block;
        text-decoration: none;
        transform: scale(0.7);
      }


@media (max-width: 480px)
{
  #sliderPages
  {
    margin-left: 0;
  }
  .card
  {
    /* width: 150px; */
    height: 100%;
    margin: 0 10px;
    border: 1px solid black;
    border-radius: 20px;
    padding: 15px;
    background-color: white;
  }
  .card h3
  {
    font-size: 18px;
  }
  .slideButtons
    {
      position: relative;
      top:unset;
      /* bottom: 0; */
    }

}

/* ------------- Aside END -----------------*/
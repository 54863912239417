/* ---------------- Header BEGIN ---------------*/

header
{
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
header>img
{
    max-height: 150px;
    max-width: 100%;
}
header>h1
{
    margin: 0 auto;
    text-align: center;
    margin: 10px 0;
}


/* ---------------- Header END ---------------*/